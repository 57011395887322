import { makeQueryFunction, makeUseQueryHook } from '../functions'
import { GetEmbeddedDocument } from '../queries/GetEmbedded.graphql'

export const useGetEmbedded = makeUseQueryHook({
    query: GetEmbeddedDocument,
})

export const getEmbedded = makeQueryFunction({
    query: GetEmbeddedDocument,
})
