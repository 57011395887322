import { promises as fs } from 'fs'
import type { DocumentNode } from 'graphql'
import path from 'path'

import type { ContentfulClient } from '../../graphql/client'
import { getOrCreateContentfulClient } from '../../graphql/client'
import { firstFromCollection } from '../../graphql/collection'
import type { PageMetaFragment } from '../../graphql/fragments/PageMetaFragment.graphql'
import { requestQuery } from '../../graphql/functions'
import { getEmbedded } from '../../graphql/hooks/embedded'
import { getVideo } from '../../graphql/hooks/video'
import { GetAnnouncementDocument } from '../../graphql/queries/GetAnnouncement.graphql'
import { GetBackgroundImageSectionDocument } from '../../graphql/queries/GetBackgroundImageSection.graphql'
import { GetBannerDocument } from '../../graphql/queries/GetBanner.graphql'
import { GetComparisonTableSectionDocument } from '../../graphql/queries/GetComparisonTableSection.graphql'
import { GetCreditMattersTableDocument } from '../../graphql/queries/GetCreditMattersTable.graphql'
import { GetCtaSectionDocument } from '../../graphql/queries/GetCTASection.graphql'
import { GetEmbeddedDocument } from '../../graphql/queries/GetEmbedded.graphql'
import { GetEmbeddedTypeFormDocument } from '../../graphql/queries/GetEmbeddedTypeform.graphql'
import { GetFeatureHeroDocument } from '../../graphql/queries/GetFeatureHero.graphql'
import { GetFeatureSectionThreeColumnDocument } from '../../graphql/queries/GetFeatureSectionThreeColumn.graphql'
import { GetFeatureSectionV2Document } from '../../graphql/queries/GetFeatureSectionV2.graphql'
import { GetFormSectionDocument } from '../../graphql/queries/GetFormSection.graphql'
import { GetFullWidthVideoDocument } from '../../graphql/queries/GetFullWidthVideo.graphql'
import { GetHeroDocument } from '../../graphql/queries/GetHero.graphql'
import { GetHeroV2Document } from '../../graphql/queries/GetHeroV2.graphql'
import { GetHomepageHeroDocument } from '../../graphql/queries/GetHomepageHero.graphql'
import { GetHorizontalScrollerDocument } from '../../graphql/queries/GetHorizontalScroller.graphql'
import { GetMarqueeDocument } from '../../graphql/queries/GetMarquee.graphql'
import { GetMultiCopySectionDocument } from '../../graphql/queries/GetMultiCopySection.graphql'
import { GetMultiImageSectionDocument } from '../../graphql/queries/GetMultiImageSection.graphql'
import { GetNineBlockGridDocument } from '../../graphql/queries/GetNineBlockGrid.graphql'
import type { PageFragment } from '../../graphql/queries/GetPage.graphql'
import { GetPageDocument } from '../../graphql/queries/GetPage.graphql'
import { GetPageMetaDocument } from '../../graphql/queries/GetPageMeta.graphql'
import { GetPhoneScrollerDocument } from '../../graphql/queries/GetPhoneScroller.graphql'
import { GetSectionDocument } from '../../graphql/queries/GetSection.graphql'
import { GetSectionOfBentoBoxesDocument } from '../../graphql/queries/GetSectionOfBentoBoxes.graphql'
import { GetSectionOfFaQsDocument } from '../../graphql/queries/GetSectionOfFAQs.graphql'
import { GetSectionOfHeroesDocument } from '../../graphql/queries/GetSectionOfHeroes.graphql'
import { GetSectionOfInvestorsDocument } from '../../graphql/queries/GetSectionOfInvestors.graphql'
import { GetSectionOfPressBannersDocument } from '../../graphql/queries/GetSectionOfPressBanners.graphql'
import { GetSectionOfReviewsDocument } from '../../graphql/queries/GetSectionOfReviews.graphql'
import { GetSectionOfThumbnailsDocument } from '../../graphql/queries/GetSectionOfThumbnails.graphql'
import { GetSideBySideDocument } from '../../graphql/queries/GetSideBySide.graphql'
import { GetSimpleHeaderSectionDocument } from '../../graphql/queries/GetSimpleHeaderSection.graphql'
import { GetSectionOfStepsDocument } from '../../graphql/queries/GetSteps.graphql'
import { GetVideoDocument } from '../../graphql/queries/GetVideo.graphql'
import { extendConsole } from '../../shared/logging/console'
import { ERRORS } from '../utils/errors'
import { ThemeOptions } from '../utils/withTheme'

const logger = extendConsole('fetchStaticPage')

type PageSectionFragmentType = NonNullable<
    PageFragment['sections']
>['items'][0]['type']

type PageSectionItemType = NonNullable<PageFragment['sections']>['items'][0]

/**
 * Fetch page JSON from [slug].json file
 */
export async function fetchStaticPage(slug: string) {
    const pagePath = `data/${slug}.json`

    try {
        const data = await fs.readFile(
            path.join(process.cwd(), pagePath),
            'utf-8'
        )

        if (!data) {
            throw new Error(`${ERRORS.PAGE_NOT_FOUND}: ${slug}`)
        }

        return {
            ...JSON.parse(data),
        }
    } catch (error) {
        throw new Error(`${ERRORS.PAGE_NOT_FOUND}: ${slug}`)
    }
}

export const fetchContentfulPage = async (slug: string) => {
    const version = process.env.NEXT_PUBLIC_PAGE_VERSION_SUFFIX
    if (version && !slug.endsWith(version)) {
        try {
            return await fetchContentfulPageBySlug(slug + version)
        } catch (error) {
            console.warn(
                `Could not find page for version suffix ${version}, falling back to default`
            )
        }
    }
    return fetchContentfulPageBySlug(slug)
}

const fetchContentfulPageBySlug = async (slug: string) => {
    const client = getOrCreateContentfulClient('/')

    const data = await requestQuery(client, {
        query: GetPageDocument,
        variables: { slug, includeBanner: true, includeBody: true },
    })

    if (!data?.data?.pages?.items?.length) {
        throw new Error(`${ERRORS.PAGE_NOT_FOUND}: ${slug}`)
    }

    const page = firstFromCollection(data.data.pages)

    if (!page) {
        throw new Error(`${ERRORS.PAGE_NOT_FOUND}: ${slug}`)
    }

    const sectionIds = page?.sections?.items as Array<{
        type: PageSectionFragmentType
        sys: { id: string }
    }>

    // fetch meta and sections for the page
    const [metaData, sections] = await Promise.all([
        fetchContentfulPageMetaData(client, page?.meta?.sys?.id as string),
        fetchContentfulPageSections(client, sectionIds),
    ])

    return {
        ...page,
        theme: {
            key: ThemeOptions.cyanora, // set a default theme for pages that dont have one published in contentful yet
            ...(page?.theme || {}),
        },
        meta: metaData,
        sections: {
            items: sections ?? [],
        },
    }
}

export const fetchContentfulPageSections = async (
    client: ContentfulClient,
    sections: { type: PageSectionFragmentType; sys: { id: string } }[]
): Promise<Array<PageSectionItemType>> => {
    // map section type => graphql query
    const typeToQueryMap: Partial<
        Record<PageSectionFragmentType, DocumentNode>
    > = {
        // legacy sections
        Announcement: GetAnnouncementDocument,
        Embedded: GetEmbeddedDocument,
        Section: GetSectionDocument,
        SectionOfInvestors: GetSectionOfInvestorsDocument,
        SectionOfPressBanners: GetSectionOfPressBannersDocument,
        Steps: GetSectionOfStepsDocument,
        Video: GetVideoDocument,
        // newly added sections
        BackgroundImageSection: GetBackgroundImageSectionDocument,
        CtaSection: GetCtaSectionDocument,
        EmbeddedTypeform: GetEmbeddedTypeFormDocument,
        FeatureHero: GetFeatureHeroDocument,
        FullWidthVideo: GetFullWidthVideoDocument,
        FeatureSectionThreeColumn: GetFeatureSectionThreeColumnDocument,
        FeatureSectionV2: GetFeatureSectionV2Document,
        Hero: GetHeroDocument,
        HeroV2: GetHeroV2Document,
        HeroV2Carousel: GetSectionOfHeroesDocument,
        BentoBox: GetSectionOfBentoBoxesDocument,
        SimpleHeaderSection: GetSimpleHeaderSectionDocument,
        CreditMattersTable: GetCreditMattersTableDocument,
        NineBlockGrid: GetNineBlockGridDocument,
        HomepageHero: GetHomepageHeroDocument,
        HorizontalScroller: GetHorizontalScrollerDocument,
        Marquee: GetMarqueeDocument,
        MultiCopySection: GetMultiCopySectionDocument,
        MultiImageSection: GetMultiImageSectionDocument,
        PhoneScroller: GetPhoneScrollerDocument,
        SideBySide: GetSideBySideDocument,
        SectionOfReviews: GetSectionOfReviewsDocument,
        SectionOfFaQs: GetSectionOfFaQsDocument,
        SectionOfThumbnails: GetSectionOfThumbnailsDocument,
        Banner: GetBannerDocument,
        FormSection: GetFormSectionDocument,
        ComparisonTable: GetComparisonTableSectionDocument,
    }

    // map all sections to a graphql query by section type
    const requests = sections
        ?.map((section) => {
            const key = section.type

            if (!typeToQueryMap[key]) return

            const query = typeToQueryMap[key]

            if (!query) return

            return requestQuery(client, {
                query,
                variables: { id: section.sys.id },
            }).then(async (item: any) => {
                // if type is section of content and has a video, fetch the nested video too
                if (key === 'Section' && !!item.data.section.video) {
                    try {
                        const video = item.data.section.video
                        const method =
                            video.type === 'Embedded' ? getEmbedded : getVideo
                        const responseKey =
                            video.type === 'Embedded' ? 'embedded' : 'video'
                        const result = await method(client, {
                            variables: { id: video.sys.id },
                        })

                        return {
                            ...item?.data?.section,
                            video: {
                                ...item.data.section.video,
                                ...result.data[responseKey],
                            },
                        }
                    } catch (error) {
                        logger.error(error)
                    }
                }

                return item?.data?.section || item?.data?.embedded
            })
        })
        .filter(Boolean)

    if (!requests) {
        return []
    }

    // fetch all section queries
    const sectionItems: PageSectionItemType[] = await Promise.all(requests)

    return sectionItems
}

export const fetchContentfulPageMetaData = async (
    client: ContentfulClient,
    id: string
): Promise<PageMetaFragment | undefined> => {
    if (!id) {
        return
    }

    const response = await requestQuery(client, {
        query: GetPageMetaDocument,
        variables: { id },
    }).then((res) => res?.data?.meta)

    return response
}
